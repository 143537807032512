import { FC, ImgHTMLAttributes, ReactNode, useEffect, useState } from "react";
import { Loader } from "../icon/SvgIcon";

interface Props extends ImgHTMLAttributes<any> {
  src?: string;
  alt: string;
  className: string;
  onLoad?: (e: any) => void;
  onClick?: () => void;
  fallback?: string;
  withBg?: boolean;
}

const noneSrc = "/images/img/placeholder.svg";

const Image: FC<Props> = ({
  src,
  alt,
  className,
  onLoad,
  onClick,
  fallback = "/images/img/placeholder.svg",
  withBg = false,
}) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);
  const onError = () => {
    setImgSrc(fallback);
  };

  useEffect(() => {
    setImgSrc(src);
  }, [src]);
  return (
    <img
      src={imgSrc ? imgSrc : fallback}
      alt={alt}
      className={`${className} ${
        withBg
          ? `bg-[url("/images/img/placeholder.png")] bg-center bg-cover bg-no-repeat indent-[100%] whitespace-nowrap overflow-hidden`
          : ""
      }`}
      // onError={({ currentTarget }) => {
      //   currentTarget.onerror = null; // prevents looping
      //   currentTarget.src = noneSrc;
      // }}
      onError={onError}
      onLoad={onLoad}
      onClick={onClick}
    />
  );
};

export default Image;
