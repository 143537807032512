import "../styles/globals.css";
import "../styles/swal.css";
import "../styles/datepicker.css";
import "react-18-image-lightbox/style.css";
import type { AppProps } from "next/app";
import { TourProvider } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { CloseCircleIcon } from "../app/components/commons/icon/SvgIcon";
import { tourGuide } from "../app/components/plugins/utils";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { ParamsQueryContextTypes } from "../app/helpers/types/CalculateTypes";
import ParamsQueryContext from "../app/context/ParamsQueryContext";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const disableBody = (e: any) => disableBodyScroll(e);
  const enableBody = (e: any) => enableBodyScroll(e);
  const [query, setQuery] = useState<ParamsQueryContextTypes>({
    filter_subscription: "",
    filter_order_by: "upcoming",
    filter_types: "",
    filter_start_date: "",
    filter_end_date: "",
  });

  const Close = ({ onClick }: any) => {
    return (
      <div
        onClick={onClick}
        style={{ position: "absolute", right: 22, top: 17 }}
        className="text-neutral-500 cursor-pointer"
      >
        <CloseCircleIcon size="20" />
      </div>
    );
  };

  useEffect(() => {
    const login =
      localStorage.getItem("b/profile") ||
      localStorage.getItem("f/profile") ||
      "";

    if (login) {
      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/brb22vhf'
      (function () {
        var w = window as any;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          } as any;
          i.q = [];
          i.c = function (args: any) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/brb22vhf";
            var x = d.getElementsByTagName("script")[0] as any;
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();

      if (router.pathname.startsWith("/b")) {
        const buyer = localStorage.getItem("b/profile") || "";
        if (buyer) {
          const exp = JSON.parse(buyer);
          (window as any).Intercom("boot", {
            api_base: process.env.NEXT_PUBLIC_INTERCOM_API_BASE,
            app_id: process.env.NEXT_PUBLIC_INTERCOM_API_ID,
            hide_default_launcher: true,
            name: `${exp.first_name} ${exp.last_name}`, // Full name
            email: `${exp.email}`, // Email address
            created_at: new Date(), // Signup date as a Unix timestamp
            user_id: `buyer_${exp.id}`,
            user_hash: exp.intercom_identity_hash,
          });
        }
      }

      if (router.pathname.startsWith("/f")) {
        const fac = localStorage.getItem("f/profile") || "";
        if (fac) {
          const exp = JSON.parse(fac);
          (window as any).Intercom("boot", {
            api_base: process.env.NEXT_PUBLIC_INTERCOM_API_BASE,
            app_id: process.env.NEXT_PUBLIC_INTERCOM_API_ID,
            hide_default_launcher: true,
            name: `${exp.first_name} ${exp.last_name}`, // Full name
            email: `${exp.email}`, // Email address
            created_at: new Date(), // Signup date as a Unix timestamp
            user_id: `facility_manager_${exp.id}`,
            user_hash: exp.intercom_identity_hash,
          });
        }
      }
      localStorage.setItem("chat-panel", "show");
      // @ts-ignore
      Intercom("onHide", () => {
        localStorage.setItem("chat-panel", "hide");
      });

      // @ts-ignore
      Intercom("onShow", () => {
        localStorage.setItem("chat-panel", "show");
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      const buyer = localStorage.getItem("b/profile") || "";
      const fac = localStorage.getItem("f/profile") || "";
      if (buyer || fac) {
        (window as any).Intercom("update");
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getQuery = router.query as ParamsQueryContextTypes | null;
    if (getQuery) {
      setQuery(getQuery);
    }
  }, [router]);

  return (
    <TourProvider
      components={{ Close }}
      steps={tourGuide}
      // afterOpen={disableBody}
      // beforeClose={enableBody}
      styles={{
        popover: (base) => ({
          ...base,
          "--reactout-accent": "#3366ff",
          borderRadius: "8px",
          padding: "17px 20px",
        }),
        badge: (base) => ({
          ...base,
          backgroundColor: "#3366ff",
          fontWeight: 500,
          fontSize: "0.75rem",
          lineHeight: "0.75rem",
          padding: "0.25rem 0.375rem",
          height: "auto",
          borderRadius: "100%",
        }),
        maskWrapper: (base) => ({
          ...base,
          color: "rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(5px)",
          opacity: 1,
        }),
        controls: (base) => ({
          ...base,
          width: "80%",
        }),
        dot: (base) => ({
          ...base,
          width: "12px",
          height: "12px",
        }),
      }}
      maskClassName="mask"
      className="helper"
      onClickClose={({ setIsOpen }) => {
        setIsOpen(false);
        localStorage.setItem("b/tour", "0");
      }}
      onClickMask={({ setIsOpen }) => {}}
    >
      <ParamsQueryContext.Provider value={query}>
        <Component {...pageProps} />
      </ParamsQueryContext.Provider>
    </TourProvider>
  );
}

export default MyApp;
