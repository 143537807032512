import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN: string =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const SENTRY_ENV: string =
  process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV;

Sentry.init({
  dsn: SENTRY_DSN || "https://examplePublicKey@o0.ingest.sentry.io/0",
  environment: SENTRY_ENV || "unknown",
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  debug: false,
  denyUrls: [
    /maps\.googleapis\.com/i,
  ],
  beforeSend(event, hint) {
    const error:any = hint.originalException;

    if(event.request?.url?.indexOf('/b/auction')){
      const ignoreErrors  = [
        "Cannot read properties of null (reading 'indexOf')",
        "Error: Not initialized."
      ]
      if(ignoreErrors.includes(error ? error : '')){
        return null;
      } 
    }

    
    return event;
  },
});
