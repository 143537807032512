import { MouseIcon } from "../commons/icon/SvgIcon";
import Image from "../commons/image/Image";

export const ScrollDown = ({
  text = "Scroll Down",
  className = "",
  onClick,
}: {
  text?: string;
  className?: string;
  onClick?: () => void;
}) => (
  <div
    className={`bg-white shadow-lv5 rounded-2lg p-2 flex flex-row items-center space-x-2 ${className}`}
    onClick={onClick}
  >
    <div className="text-neutral-600">
      <MouseIcon size="16" />
    </div>
    <div className="font-medium text-small-tight text-neutral-600">{text}</div>
  </div>
);

export const AuctionNotFound = () => (
  <div className="flex flex-col justify-center items-center h-full text-center">
    <Image
      src="/images/Illustration/box.svg"
      alt="Not Found"
      className="w-[7.75rem] h-[7.75rem] mt-9"
    />
    <div className="mt-7">
      <b className="text-neutral-900 text-title3">No Auctions Found</b>
      <p className="text-neutral-600 text-regular-tight mt-4.5">
        there’s no any active auctions in here
      </p>
    </div>
  </div>
);

export const UpcomingAuctionNotFound = () => (
  <div className="flex flex-col justify-center items-center h-full text-center">
    <div className="mt-7">
      <b className="text-neutral-900 text-title3">No Upcoming Auctions</b>
      <p className="text-neutral-600 text-regular-tight mt-4.5 text-center w-[20rem]">
        There's no upcoming auctions in your watchlist
      </p>
    </div>
  </div>
);

export const NotifNotFound = () => (
  <div className="flex flex-col justify-center items-center h-full text-center">
    <div className="mt-7">
      <b className="text-neutral-900 text-title3">No Activities</b>
      <p className="text-neutral-600 text-regular-tight mt-4.5 text-center w-[18rem]">
        there’s no any recent activities from you
      </p>
    </div>
  </div>
);

export const tourGuide = [
  {
    selector: '[data-tour="step-search"]',
    content: () => (
      <div className="text-neutral-900 font-normal text-small-normal w-[90%]">
        Enter your preferred location or zip code to find auctions
      </div>
    ),
  },
  {
    selector: '[data-tour="step-radius"]',
    content: () => (
      <div className="text-neutral-900 font-normal text-small-normal w-[90%]">
        Adjust your location radius to find more auctions
      </div>
    ),
  },
  {
    selector: '[data-tour="step-map"]',
    content: () => (
      <div className="text-neutral-900 font-normal text-small-normal w-[90%]">
        The maps shows all the auctions happening in your chosen location &
        radius
      </div>
    ),
  },
];
