import { Context, createContext } from "react";
import {
  ParamsQueryContextTypes,
  ParamsQueryTypes,
} from "../helpers/types/CalculateTypes";

const defaultValue: ParamsQueryContextTypes = {
  filter_subscription: "",
  filter_order_by: "upcoming",
  filter_types: "",
  filter_start_date: "",
  filter_end_date: "",
};

const ParamsQueryContext: Context<ParamsQueryContextTypes> =
  createContext(defaultValue);

export default ParamsQueryContext;
